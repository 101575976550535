export const semanticSuggestionsQuery = `query suggestions($searchText: String!, $categoryCode: String!) {
  search(limit: 5, typeAhead: $searchText) {
    profiles {
      id
      name
      categoryInfo {
        seo {
          plural {
            term
          }
        }
      }
      location {
        address {
          address1
          state
          city
        }
      }
      siteUrls {
        relativeUri
      }
      storefrontCard {
        url
      }
    }
  }
  semanticSuggestions(searchText: $searchText, categoryCode:$categoryCode, limit: 6) {
    facet
    facetId
    searchTerm
    semanticTerm
    semanticType
    similarityScore
  }
}`;
